:root {
  --main-color: #011627;
  --accent-1: #00223d;
  --accent-2: #48a9a6;
  --accent-3: #f5f1e3;
}

body {
  background-color: var(--main-color);
  color: white;
}

.divider {
  border-bottom: 3px solid var(--accent-1);
  margin-bottom: 1rem;
  width: 10rem;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .divider {
    width: 15rem;
  }

  #about {
    scroll-margin-top: 0;
  }
}
