.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--main-color);
  color: white;
}

.hero-name {
  font-size: 5rem;
  font-weight: 200;
  line-height: 5rem;
  text-align: center;
}

.hero-tagline {
  font-weight: 200;
}

.word1,
.word2,
.word3,
.word4,
.word5 {
  display: inline-block;
}

.arrow-nav-container {
  display: flex;
  gap: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 90%;
}

.nav-arrow {
  width: 3rem;
  opacity: 0.4;
}

#tsparticles {
  height: 100vh;
  position: absolute;
}
