.navbar {
  padding: 0.5rem 1rem;
  background-color: var(--accent-1);
  color: white;
}

.nav-container {
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
}

.navbar-brand {
  font-size: 1.3rem;
  color: white;
  font-weight: 300;
}

a.navbar-brand:hover {
  color: white;
}

.nav-link {
  color: white;
}

a.nav-link.active {
  color: white;
}

@media screen and (min-width: 768px) {
  .navbar-brand {
    font-size: 2rem;
  }

  .navbar-nav {
    align-items: center;
  }

  .nav-link {
    font-size: 1.2rem;
    align-self: center;
    justify-self: center;
  }
}

@media screen and (min-width: 992px) {
}
