.project-card {
  width: 20rem;
  height: 15rem;
  background-color: var(--accent-1);
  text-align: center;
  border: none;
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow: 0px 8px 10px var(--accent-1);
}

img {
  background-color: var(--main-color);
}

.card-img-top {
  height: 15rem;
  object-fit: cover;
  background-color: var(--main-color);
  border-radius: 10px;
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  font-weight: 200;
}

.blur {
  filter: blur(5px) brightness(40%);
  transform: scale(1.04);
  transition: filter 0.3s ease, transform 0.3s ease;
}

.shadow {
  box-shadow: none;
  transition: box-shadow 0.5s ease;
}

.modal-content {
  background-color: var(--main-color);
}

.modal-header {
  border-bottom: none;
  background-color: var(--accent-1);
}

.modal-title {
  font-weight: 200;
  font-size: 1.5rem;
}

.modal-footer {
  border-top: 3px solid var(--accent-1);
}

.modal-body {
  display: flex;
  flex-direction: column;
  font-weight: 250;
  font-size: 1.1rem;
  gap: 1rem;
}

.modal-btn {
  background-color: var(--accent-1);
  border: none;
}

.modal-btn-close {
  background-color: rgb(45, 45, 45);
  border: none;
}

.carousel-inner {
  border-radius: 10px;
}

.carousel-item {
  height: 20rem;
}

img.carousel-item-img {
  object-fit: fill;
}

.btn:hover {
  background-color: var(--accent-2);
  border: none;
}

@media screen and (min-width: 768px) {
  .modal-body {
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
  }

  .carousel-item {
    height: 25rem;
  }
}
