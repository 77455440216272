.about-section {
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
  background-color: var(--main-color);
  color: white;
}

.about-title {
  font-weight: 200;
  font-size: 2rem;
  text-align: center;
}

.about-picture {
  border-radius: 100%;
  width: 8rem;
}

.about-me-blurb {
  font-size: 1rem;
  font-weight: 300;
  background-color: var(--accent-1);
  padding: 1rem;
  border-radius: 10px;
}

.tech-stack {
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2rem;
}

.dev-icon {
  width: 50px;
}

#about {
  scroll-margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .about-title {
    font-size: 3rem;
  }
  .about-picture {
    width: 13rem;
    margin-bottom: 1rem;
  }

  .about-me-blurb {
    width: 35rem;
    font-size: 1.5rem;
  }
  #about {
    scroll-margin-top: 0;
  }
}
