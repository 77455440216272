.contact-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  background-color: var(--main-color);
}

.contact-title {
  font-weight: 200;
  font-size: 2rem;
  text-align: center;
}

.contact-section p {
  font-size: 1rem;
  font-weight: 300;
}

.social-links {
  font-size: 3rem;
  display: flex;
  gap: 2rem;
}

i {
  color: var(--accent-2);
}

@media screen and (min-width: 768px) {
  .contact-title {
    font-weight: 200;
    font-size: 3rem;
    text-align: center;
  }

  .contact-section p {
    font-size: 1.5rem;
    font-weight: 300;
    width: 43rem;
  }
}
