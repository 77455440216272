.projects-section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: var(--main-color);
}

.projects-title {
  font-weight: 200;
  font-size: 2rem;
  text-align: center;
}

#projects {
  scroll-margin-top: 80px;
}

@media screen and (min-width: 768px) {
  .projects-title {
    font-weight: 200;
    font-size: 3rem;
    text-align: center;
  }
  .projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 43rem;
    justify-items: center;
    align-items: center;
  }

  #projects {
    scroll-margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .projects-title {
    font-weight: 200;
    font-size: 3rem;
    text-align: center;
  }
  .projects-container {
    display: flex;
    width: 60rem;
    justify-items: center;
    align-items: center;
    gap: 1rem;
  }

  .projects-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: var(--main-color);
  }
}
